@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 20 60% 95%;
    --foreground: 20 48% 42%;
    --title: 20 46% 35%;

    --card: 20 60% 98%;
    --card-border: 20 61% 82%;

    --popover: 20 60% 95%;
    --popover-foreground: 20 48% 42%;

    --navigation-border: 20, 61%, 82%;

    --progress-border: 20, 61%, 82%;
    --progress-bar: 20, 60%, 98%;
    --progress-bar-background: 20, 63%, 92%;

    --instruction-background: 20, 63%, 92%;

    --menu-background: 20, 60%, 98%;
    --menu-background-active: 20deg 62% 90%;
    --menu-border: 18deg 77% 99%;

    --primary: 23 89% 40%;
    --primary-foreground: 0 0% 100%;
    --primary-border: 20 80% 34%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 23 89% 40%;

    --tertiary: 20 63% 92%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 23 89% 40%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 100%;

    --border: 220 13% 91%;
    --input-border: 20, 61%, 82%;
    --input-background: 0 0% 100%;
    --input-background-active: 40, 100%, 96%;
    --input-border-active: 30, 97%, 53%;
    --input-check-active: 26, 92%, 48%;
    --input-text-active: 20, 41%, 31%;
    --input-text-inactive: 20, 80%, 34%;

    --ring: 20 61% 72%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground antialiased;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings:
      'ss11' on,
      'ss09' on,
      'ss10' on,
      'ss08' on,
      'ss07' on,
      'ss05' on,
      'ss04' on,
      'ss03' on,
      'ss06' on,
      'ss12' on,
      'ss01' on;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-bold text-title;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child) {
    margin-bottom: 0.3em;
  }

  h1 {
    @apply text-4xl;
  }

  h2,
  h3,
  h4 {
    @apply text-lg;
  }

  p {
    @apply text-lg font-medium;
  }

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  p a {
    @apply underline font-medium;
  }

  mux-player {
    --media-object-fit: cover;
    --media-object-position: center;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --top-captions-button: none;

    /*Please note: keep this the same as the aspect ratio of VideoStep.tsx*/
    aspect-ratio: 9 / 16;
    height: 75dvh;
    border-radius: 0.8rem;
  }

  mux-player::part(play) {
    background: rgba(147, 121, 121, 0.5);
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
    padding: 1.2rem;
  }

  mux-player::part(fullscreen) {
    display: none;
  }
}
